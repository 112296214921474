export default class AppConfig {
    static get API_URL() {
        return `${this.ROOT_API_URL}/api/v3/`;
    }

    static get ROOT_API_URL() {
        return "https://api.stc.kujakuja.com";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get MAPBOX_GL_ACCESS_TOKEN() {
        return "pk.eyJ1IjoiYmFycnlsYWNoYXBlbGxlIiwiYSI6ImNrd2R3ZmR4aTUxZmoyb3BhOTM3anQ5ZTYifQ.0tpdg1loc-d1eHewHdL-CQ";
    }

    static get MAPBOX_GL_MAP_STYLE_URL() {
        return "mapbox://styles/barrylachapelle/cjlwoyk4e3skr2smu4fl2xrjv";
    }

    static get FRONT_END_URL() {
        return "https://stc.kujakuja.com/en/";
    }
}
